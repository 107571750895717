import React, { useMemo } from 'react'
import Logo from 'components/Logo'
import {
  Navbar, useTranslation, useLocalizedUrl, useLanguage
} from '@sailogy/shipwright'
import { K } from './i18n'

interface Props {
  overlay?: boolean
  overrideLogoURL?: string
  hideProfile?: boolean
}


const WebsiteNavbar: React.FC<Props> = ({
  overlay,
  overrideLogoURL,
  hideProfile = false
}) => {
  const t = useTranslation<K>()
  const localizedUrl = useLocalizedUrl()
  const [language] = useLanguage()
  const menu = useMemo(() => [
    {
      label: t('Our dedicated bases'),
      href: localizedUrl('/search')
    },
    {
      label: t('Yacht invest'),
      href: localizedUrl('/p/yachtinvest')
    }
  ], [language, localizedUrl])

  return (
    <Navbar
      logo={Logo}
      // @ts-ignore
      menu={menu}
      overlay={overlay}
      forcePrimaryColorLogo
      overrideLogoURL={overrideLogoURL}
      hideProfile={hideProfile}
    />
  )
}

export default React.memo(WebsiteNavbar)
