import React from 'react'

type Props = React.SVGProps<SVGSVGElement>

const FiveSeasonsYachtingLogo = (props: Props) => (
  <svg
    viewBox="0 -20 637.93335 57.706669"
    version="1.1"
    width={300}
    height={34}
    {...props}
  >
    <g
      transform="matrix(1.3333333,0,0,-1.3333333,0,23.546667)"
      id="g10"
    >
      <g
        transform="scale(0.1)"
        id="Logo"
        fill="currentColor"
      >
        <path
          id="path14"
          d="m 4784.54,2.43359 h -142.55 c -36.65,0 -64.99,7.45704 -85.02,22.38281 -20.03,14.9141 -30.04,36.0742 -30.04,63.4883 0,27.4023 10.01,48.5623 30.04,63.4883 20.03,14.918 48.37,22.375 85.02,22.375 h 142.55 v -30.406 h -139.88 c -24.32,0 -43.1,-4.864 -56.31,-14.594 -13.22,-9.73 -19.83,-23.434 -19.83,-41.1055 0,-17.6836 6.61,-31.3828 19.83,-41.1133 13.21,-9.7304 31.99,-14.5937 56.31,-14.5937 h 102.42 v 43.5781 h -61.23 l 16.52,27.2504 h 82.17 V 2.43359"
        />
        <path
          id="path16"
          d="M 0,174.172 H 209.691 V 143.277 H 38.6797 V 2.43359 L 0,2.43359 V 174.172"
        />
        <path
          id="path18"
          d="M 276.883,2.47656 H 236.504 V 174.133 h 40.379 V 2.47656"
        />
        <path
          id="path20"
          d="m 293.492,174.172 h 44.516 L 425.094,33.3242 h 14.594 l 87.335,140.8478 h 42.813 L 463.043,2.43359 H 400.285 L 293.492,174.172"
        />
        <path
          id="path22"
          d="M 592.215,174.172 H 812.852 V 145.227 H 631.133 V 58.1406 c 0,-9.4101 3.004,-16.2226 9.004,-20.4375 5.996,-4.2187 15.57,-6.3242 28.703,-6.3242 H 812.852 V 2.43359 H 663.246 c -24.812,0 -42.851,4.33594 -54.129,13.01171 -11.273,8.6758 -16.902,22.586 -16.902,41.7188 V 174.172"
        />
        <path
          id="path24"
          d="m 902.859,33.3242 h 151.791 c 11.51,0 20.27,1.8242 26.27,5.4766 6,3.6484 9.01,8.957 9.01,15.9297 0,6.8125 -3.01,12.0429 -9.01,15.6953 -6,3.6445 -14.76,5.4687 -26.27,5.4687 h -77.353 c -24.004,0 -42.414,4.2618 -55.223,12.7696 -12.812,8.5156 -19.215,20.6369 -19.215,36.3669 0,15.731 6.403,27.856 19.215,36.367 12.809,8.516 31.219,12.774 55.223,12.774 H 1129.09 V 143.277 H 979.973 c -23.844,0 -35.762,-6.652 -35.762,-19.945 0,-13.301 11.918,-19.949 35.762,-19.949 h 77.357 c 24,0 42.4,-4.3361 55.21,-13.0119 12.82,-8.6758 19.23,-21.207 19.23,-37.582 0,-16.2227 -6.37,-28.668 -19.1,-37.3438 C 1099.93,6.76953 1081.49,2.43359 1057.33,2.43359 H 902.859 V 33.3242"
        />
        <path
          id="path26"
          d="m 1162.9,174.172 h 220.64 V 145.227 H 1201.82 V 58.1406 c 0,-9.4101 3,-16.2226 9,-20.4375 6,-4.2187 15.57,-6.3242 28.7,-6.3242 h 144.02 V 2.43359 h -149.61 c -24.81,0 -42.85,4.33594 -54.13,13.01171 -11.27,8.6758 -16.9,22.586 -16.9,41.7188 V 174.172"
        />
        <path
          id="path28"
          d="M 1702.2,33.3242 H 1854 c 11.51,0 20.27,1.8242 26.27,5.4766 5.99,3.6484 9,8.957 9,15.9297 0,6.8125 -3.01,12.0429 -9,15.6953 -6,3.6445 -14.76,5.4687 -26.27,5.4687 h -77.36 c -24,0 -42.41,4.2618 -55.22,12.7696 -12.81,8.5156 -19.22,20.6369 -19.22,36.3669 0,15.731 6.41,27.856 19.22,36.367 12.81,8.516 31.22,12.774 55.22,12.774 h 151.79 v -30.895 h -149.11 c -23.85,0 -35.77,-6.652 -35.77,-19.945 0,-13.301 11.92,-19.949 35.77,-19.949 h 77.35 c 24,0 42.41,-4.3361 55.22,-13.0119 12.81,-8.6758 19.22,-21.207 19.22,-37.582 0,-16.2227 -6.37,-28.668 -19.1,-37.3438 C 1899.28,6.76953 1880.84,2.43359 1856.67,2.43359 H 1702.2 V 33.3242"
        />
        <path
          id="path30"
          d="m 2177.41,45.6094 c 12.73,9.8125 19.09,24.0429 19.09,42.6953 0,18.8083 -6.41,33.0823 -19.21,42.8123 -12.82,9.727 -31.47,14.594 -55.95,14.594 h -48.17 c -24.49,0 -43.1,-4.867 -55.83,-14.594 -12.73,-9.73 -19.09,-24.004 -19.09,-42.8123 0,-18.6524 6.36,-32.8828 19.09,-42.6953 12.73,-9.8086 31.34,-14.7149 55.83,-14.7149 h 48.17 c 24.65,0 43.33,4.9063 56.07,14.7149 z M 1985.96,22.7422 c -19.7,15.1641 -29.55,37.0156 -29.55,65.5625 0,28.5393 9.85,50.3903 29.55,65.5543 19.71,15.164 48.2,22.743 85.51,22.743 h 51.57 c 37.3,0 65.8,-7.579 85.5,-22.743 19.71,-15.164 29.56,-37.015 29.56,-65.5543 0,-28.5469 -9.85,-50.3984 -29.56,-65.5625 C 2188.84,7.58203 2160.34,0 2123.04,0 h -51.57 c -37.31,0 -65.8,7.58203 -85.51,22.7422"
        />
        <path
          id="path32"
          d="m 2269.23,174.172 h 62.28 L 2465.54,34.0586 h 8.51 V 174.172 h 37.47 V 2.43359 h -62.51 L 2314.97,142.551 h -8.52 V 2.43359 h -37.22 V 174.172"
        />
        <path
          id="path34"
          d="m 2543.86,33.3242 h 151.8 c 11.51,0 20.27,1.8242 26.27,5.4766 6,3.6484 9.01,8.957 9.01,15.9297 0,6.8125 -3.01,12.0429 -9.01,15.6953 -6,3.6445 -14.76,5.4687 -26.27,5.4687 h -77.36 c -24,0 -42.41,4.2618 -55.21,12.7696 -12.82,8.5156 -19.23,20.6369 -19.23,36.3669 0,15.731 6.41,27.856 19.23,36.367 12.8,8.516 31.21,12.774 55.21,12.774 h 151.8 v -30.895 h -149.12 c -23.84,0 -35.76,-6.652 -35.76,-19.945 0,-13.301 11.92,-19.949 35.76,-19.949 h 77.36 c 23.99,0 42.41,-4.3361 55.22,-13.0119 12.8,-8.6758 19.22,-21.207 19.22,-37.582 0,-16.2227 -6.38,-28.668 -19.1,-37.3438 C 2740.94,6.76953 2722.5,2.43359 2698.34,2.43359 H 2543.86 V 33.3242"
        />
        <path
          id="path36"
          d="M 3008.73,59.5977 V 2.43359 H 2968.6 V 59.5977 L 2851.58,174.172 h 50.37 l 86.11,-83.4376 h 3.64 l 86.12,83.4376 h 48.16 L 3008.73,59.5977"
        />
        <path
          id="path38"
          d="m 3609.58,143.277 h -125.53 c -24.8,0 -43.7,-4.703 -56.67,-14.109 -12.98,-9.402 -19.47,-23.027 -19.47,-40.8633 0,-17.8438 6.49,-31.4649 19.47,-40.8711 12.97,-9.4023 31.87,-14.1094 56.67,-14.1094 h 125.53 V 2.43359 h -127.23 c -37.62,0 -66.37,7.33204 -86.23,22.01171 -19.86,14.6797 -29.8,35.961 -29.8,63.8594 0,27.8863 9.94,49.1723 29.8,63.8553 19.86,14.672 48.61,22.012 86.23,22.012 h 127.23 v -30.895"
        />
        <path
          id="path40"
          d="m 3644.61,174.172 h 40.14 v -69.57 h 161.77 v 69.57 h 40.37 V 2.43359 h -40.37 V 73.7031 H 3684.75 V 2.43359 h -40.14 V 174.172"
        />
        <path
          id="path42"
          d="m 4014.36,143.277 h -103.38 v 30.895 h 247.14 V 143.277 H 4054.49 V 2.43359 h -40.13 V 143.277"
        />
        <path
          id="path44"
          d="m 4253.75,174.172 h 62.27 L 4450.05,34.0586 h 8.51 V 174.172 h 37.46 V 2.43359 h -62.5 L 4299.47,142.551 h -8.51 V 2.43359 h -37.21 V 174.172"
        />
        <path
          id="path46"
          d="M 4222.58,2.47656 H 4182.2 V 174.133 h 40.38 V 2.47656"
        />
        <path
          id="path48"
          d="M 1684.21,2.43359 1577.9,174.172 h -67.63 L 1403.97,2.43359 h 42.08 l 86.12,142.06241 h 22.13 l 49.51,-81.6757 h -60.49 L 1526.8,35.5742 h 93.53 l 20.09,-33.14061 h 43.79"
        />
        <path
          id="path50"
          d="M 91.5195,103.184 75.0039,75.9375 H 173.699 V 103.184 H 91.5195"
        />
        <path
          id="path52"
          d="M 694.445,103.184 677.93,75.9375 h 98.695 v 27.2465 h -82.18"
        />
        <path
          id="path54"
          d="M 1265.12,103.184 1248.6,75.9375 h 98.7 v 27.2465 h -82.18"
        />
        <path
          id="path56"
          d="M 3367.3,2.43359 3260.99,174.172 h -67.62 L 3087.07,2.43359 h 42.08 l 86.11,142.06241 h 22.14 l 49.51,-81.6757 h -60.5 L 3209.9,35.5742 h 93.52 l 20.09,-33.14061 h 43.79"
        />
      </g>
    </g>
  </svg>
)

export default FiveSeasonsYachtingLogo
