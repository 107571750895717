import Head from 'components/Head'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import {
  NavigationProvider,
  ErrorContent,
  useTranslation,
  useLocalizedUrl
} from '@sailogy/shipwright'
import { K } from 'i18n/error'

interface Props {
  error: number
}


const ErrorPage: React.FC<Props> = ({ error }) => {
  const t = useTranslation<K>()
  const localizedUrl = useLocalizedUrl()

  return (
    <NavigationProvider path="/:error" vars={{ error }}>
      <Head title={t(error === 404 ? 'Error 404' : 'Error 500')} noIndex />
      <Navbar />
      <ErrorContent
        title={t(error === 404 ? 'Error 404' : 'Error 500')}
        content={t(error === 404 ? 'It looks like you followed the wrong route' : 'Hold on sailor, we are doing some maintenance')}
        ctaLabel={t('Back to homepage')}
        ctaUrl={localizedUrl('/')}
      />
      <Footer />
    </NavigationProvider>
  )
}

export default ErrorPage
